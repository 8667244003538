import React, { useState, useRef, useEffect } from "react";
import "../css/style.css";
import "../css/responsive.css";
import NavBar from "../Components/NavBar";
import apple from "../Image/apple.svg";
import plus from "../Image/plus.svg";
import cimg1 from "../Image/cimg1.webp";
import cimg2 from "../Image/cimg2.webp";
import cimg3 from "../Image/cimg3.webp";
import cimg4 from "../Image/cimg4.webp";
import FadeInSection from "../Components/FadInSection";
import phone1 from "../Image/phone1.png";
import phone2 from "../Image/phone2.png";
import bg from "../Image/bg.png";
import Slider from "../Components/Carousel";
import FAQs from "../Components/Faq";
import Footer from "../Components/Footer";
import Pop1 from "../Components/Pop1";
import img1 from "../Image/topImg1.svg";
import img2 from "../Image/topImg2.svg";
import img3 from "../Image/topImg3.svg";
import img4 from "../Image/topImg4.svg";
import img5 from "../Image/exImg1.svg";
import img6 from "../Image/exImg2.svg";
import img7 from "../Image/exImg3.svg";
import img8 from "../Image/exImg4.svg";
import Pop2 from "../Components/Pop2";
import portImg from "../Image/portImg.png";
import aImg from "../Image/analyticImg.png";
import { useMediaQuery } from "react-responsive";
import imgb1 from "../Image/abImg1.svg";
import imgb2 from "../Image/abImg2.svg";
import imgb3 from "../Image/abImg3.svg";
import imgb4 from "../Image/abImg4.svg";
import TermsOfUse from "./TermsOfUse";
import PrivacyandPolicies from "./PrivacyAndPolicy";
import guy1 from "../Image/guy1.webp";
import ppl from "../Image/ppl.webp";
import MarqueeSlider from "../Components/Carousel";

export default function HomeScreen() {
  const [pop1, setPop1] = useState(false);
  const [pop2, setPop2] = useState(false);
  const [pop3, setPop3] = useState(false);
  const [pop4, setPop4] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ maxWidth: 1250, minWidth: 767 });
  const [showAbt, setShowAbt] = useState(false);
  const [colab, showColab] = useState(false);
  const [podcast, showPodcast] = useState(false);
  const AppF = useRef(null);
  const BrandV = useRef(null);
  const Faq = useRef(null);
  const [termsOfuse, shoeTermsOFuse] = useState(false);
  const [pp, setPP] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true); // Left button disabled initially
  const [isRightDisabled, setIsRightDisabled] = useState(false); // Right button enabled initially

  // Function to scroll the carousel by 300px
  const scrollCarousel = (direction: any) => {
    if (carouselRef.current) {
      const scrollAmount = 300; // Amount to scroll
      if (direction === "left") {
        // @ts-ignore
        carouselRef.current.scrollBy({
          left: -scrollAmount,
          behavior: "smooth",
        });
      } else {
        // @ts-ignore
        carouselRef.current.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    }
  };

  // Check if buttons should be disabled based on scroll position
  const checkButtonDisable = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;

      // Disable the left button if we're at the start
      setIsLeftDisabled(scrollLeft <= 0);

      // Disable the right button if we've scrolled to the end
      setIsRightDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  // Attach scroll event listener to the carousel
  useEffect(() => {
    if (carouselRef.current) {
      const handleScroll = () => checkButtonDisable();
      // @ts-ignore
      carouselRef.current.addEventListener("scroll", handleScroll);

      // Check the initial button states
      checkButtonDisable();

      return () => {
        if (carouselRef.current) {
          // @ts-ignore
          carouselRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, []);

  const scrollToSection = (elementRef: any) => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };
  const carouselData = [
    {
      id: 1,
      name: (
        <>
          Top-tier
          <br />
          Projects
        </>
      ),
      img: cimg1,
    },
    {
      id: 2,
      name: (
        <>
          Exclusive
          <br />
          Content
        </>
      ),
      img: cimg2,
    },
    {
      id: 3,
      name: (
        <>
          Portfolio
          <br />
          Management
        </>
      ),
      img: cimg3,
    },
    {
      id: 4,
      name: (
        <>
          Investment
          <br />
          Analytics
        </>
      ),
      img: cimg4,
    },
  ];

  const sliderImageUrl = [img1, img2, img3, img4];
  const sliderUrl3 = [imgb1, imgb2, imgb3, imgb4];
  const sliderImagUril2 = [img5, img6, img7, img8];

  return (
    <>
      <div className="body">
        {pop1 && (
          <Pop1
            sliderImageUrl={sliderImageUrl}
            text1="App feature"
            text2="Top-tier Projects"
            closePop={() => setPop1(false)}
            bText={
              <p>
                On StableBlocks you’ll discover top-tier projects and talents
                across various industries, all talents and projects are
                handpicked for their potential.
                {isMobile ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : isTab ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}{" "}
                Whether you're interested in infrastructure, entertainment,
                construction, or other industries, StableBlocks provides a
                unique platform where{" "}
                <span>
                  every opportunity is carefully vetted and worth your
                  investment.
                </span>
              </p>
            }
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
          />
        )}
        {pop2 && (
          <Pop1
            sliderImageUrl={sliderImagUril2}
            text1="App feature"
            text2="Exclusive Content"
            closePop={() => setPop2(false)}
            bText={
              <p>
                StableBlocks gives you more than just investment opportunities.
                We feature exclusive stories from project owners and get a
                behind-the-scenes look at their journey.
                {isMobile ? (
                  <>
                    <br />
                  </>
                ) : isTab ? (
                  <>
                    <br />
                  </>
                ) : (
                  ""
                )}
                By investing, <span>you're not just funding a project</span> —
                you're joining a journey toward success, with a front-row seat
                to its growth.
              </p>
            }
            img1={img5}
            img2={img6}
            img3={img7}
            img4={img8}
          />
        )}
        {pop3 && (
          <Pop2
            text1="App feature"
            text2="Portfolio Management"
            closePop={() => setPop3(false)}
            bText={
              <p>
                On StableBlocks, you can easily monitor all your investments at
                a glance or dive deeper into detailed analytics.
                <br />
                You can also <span>set portfolio goals</span> to help us
                recommend the best projects that align with your financial and
                investment objectives.
              </p>
            }
            img1={portImg}
          />
        )}
        {pop4 && (
          <Pop2
            text1="App feature"
            text2="Investment Analytics"
            closePop={() => setPop4(false)}
            bText={
              <p>
                On StableBlocks, you gain valuable insights into the performance
                of your investments. Our platform provides comparison metrics,
                allowing you to see which investments and industries are doing
                better than others.
                <br />
                With this information, you can{" "}
                <span>make informed decisions</span> for a more effective
                investment journey.
              </p>
            }
            img1={aImg}
          />
        )}
        {showAbt && (
          <Pop1
            sliderImageUrl={sliderUrl3}
            closePop={() => setShowAbt(false)}
            bText={
              <p>
                At StableBlocks, we believe that investing should be more than
                just about returns —
                <span>it’s about building the lifestyle you want.</span>
                {isMobile ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : isTab ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}{" "}
                We connect you with promising projects, talents, unique brand
                vouchers, and tools to help you make informed decisions. Whether
                you're investing for returns or reinvesting in your own
                experiences, StableBlocks is here for the entire journey.
              </p>
            }
            img1={imgb1}
            img2={imgb2}
            img3={imgb3}
            img4={imgb4}
          />
        )}
        {colab && (
          <>
            <Pop2
              text1="App feature"
              text2="Collaborate With Us"
              closePop={() => showColab(false)}
              extraClass={"collab"}
              bText={
                <>
                  <p>
                    Would you like to explore innovative funding possibilities
                    for your brand? Collaborate with us to build the right Defi
                    model for you .<br />
                    <br /> Send us a mail to discuss and explore options that
                    will fit your brand.
                  </p>
                  <a
                    href="mailto:support@usestableblocks.com"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#000" }}
                    className="aTag"
                  >
                    <div className="btn2">Send us a mail</div>
                  </a>
                </>
              }
              img1={ppl}
            />
          </>
        )}
        {podcast && (
          <>
            <Pop2
              text1="App feature"
              text2="Our Podcast"
              closePop={() => showPodcast(false)}
              bText={
                <p>
                  Be a part of our Informative sessions where we explore the
                  world of digital assets and decentralized finance with our
                  experts and collaborators. You don't want to miss out on our
                  mix of entertainment, lifestyle, wealth management and brand
                  innovation.
                  <br />
                  <br />
                  Listen to our podcast on <span>Spotify</span> or on our
                  Instagram page <span>@ourstableblocks</span>
                </p>
              }
              img1={guy1}
            />
          </>
        )}
        <NavBar
          AppF={() => {
            setPP(false);
            shoeTermsOFuse(false);
            setTimeout(() => {
              scrollToSection(AppF);
            }, 500);
          }}
          BrandV={() => {
            setPP(false);
            shoeTermsOFuse(false);
            setTimeout(() => {
              scrollToSection(BrandV);
            }, 500);
          }}
          Faq={() => {
            setPP(false);
            shoeTermsOFuse(false);
            setTimeout(() => {
              scrollToSection(Faq);
            }, 500);
          }}
        />
        <div className="topper"></div>
        {termsOfuse ? (
          <TermsOfUse />
        ) : pp ? (
          <PrivacyandPolicies />
        ) : (
          <>
            <div className="secNav">
              <img src={apple} alt="" />
              <p className="apt">Available only on App Store.</p>
            </div>
            <FadeInSection>
              <div className="banner">
                <div className="bannerContent">
                  <h3 className="bannerText">
                    We’ve Made Investing Fun and Personal.
                  </h3>
                  <button className="btn">Get StableBlocks</button>
                </div>
              </div>
            </FadeInSection>

            <section className="section1" ref={AppF}>
              <FadeInSection>
                <div className="textSec">
                  <h3>Invest in Dreams</h3>
                  <p>
                    At StableBlocks, investment is not just about financial
                    returns, It’s about investing in dreams, people and
                    experiences.
                  </p>
                </div>
              </FadeInSection>
              <FadeInSection>
                <div className="CarouselCont" ref={carouselRef}>
                  <div className="scroll">
                    {carouselData.map((item, index) => (
                      <div
                        className="carouselCard"
                        style={{ backgroundImage: `url(${item.img})` }}
                        onClick={() => {
                          index === 0
                            ? setPop1(true)
                            : index === 1
                            ? setPop2(true)
                            : index === 2
                            ? setPop3(true)
                            : setPop4(true);
                        }}
                      >
                        <div className="cTextHead">
                          <h4>{item.name}</h4>
                        </div>
                        <button
                          className="plusBtn"
                          onClick={() => {
                            index === 0
                              ? setPop1(true)
                              : index === 1
                              ? setPop2(true)
                              : index === 2
                              ? setPop3(true)
                              : setPop4(true);
                          }}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </FadeInSection>

              <div className="fpbtnCont">
                <div
                  className="fpBtnLeft"
                  onClick={() => scrollCarousel("left")}
                >
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="44"
                      height="44"
                      rx="22"
                      fill={isLeftDisabled ? `#F4F2F4` : `#E2E2E4`}
                    />
                    <path
                      d="M26.375 29.5L18.875 22L26.375 14.5"
                      stroke={isLeftDisabled ? "#BDBEBF" : "#636364"}
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="fpBtn" onClick={() => scrollCarousel("right")}>
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="44"
                      height="44"
                      rx="22"
                      fill={isRightDisabled ? `#F4F2F4` : `#E2E2E4`}
                    />
                    <path
                      d="M18.625 14.5L26.125 22L18.625 29.5"
                      stroke={isRightDisabled ? "#BDBEBF" : "#636364"}
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </section>

            <section className="brand" ref={BrandV}>
              <FadeInSection>
                <h4 className="brandText">Brand vouchers</h4>
              </FadeInSection>
              <FadeInSection>
                <div className="brandContent">
                  <div className="textSection">
                    <h4 className="textH">
                      Spend smart
                      <br />
                      Experience MORE
                    </h4>
                    <p className="textP">
                      Invest in businesses by paying upfront for their services,
                      enjoy other perks from their brand, and claim any unused
                      balance in cash at the end of your voucher period.
                    </p>
                    <button className="btn2">Get StableBlocks</button>
                  </div>
                  <div className="imgSection">
                    <img src={phone1} className="img1" alt="" />
                    <img src={phone2} alt="" />
                  </div>
                </div>
              </FadeInSection>
            </section>
            <section className="plan">
              <FadeInSection>
                <h4 className="brandText">Pricing</h4>
              </FadeInSection>
              <FadeInSection>
                <div className="planCentent">
                  <div className="plan1">
                    <h4>
                      Annual
                      <br />
                      Subscription
                    </h4>
                    <p>
                      As a new subscriber you get 3 extra months free when you
                      pay the annual subscription fee of $79.
                    </p>
                    <img src={bg} alt="" />
                  </div>
                  <div className="plan1">
                    <h4>
                      Monthly
                      <br />
                      Subscription
                    </h4>
                    <p>
                      As a new subscriber you get 1 brand reward for free when
                      you pay the monthly subscription fee of $9.
                    </p>
                    <img src={bg} alt="" />
                  </div>
                </div>
              </FadeInSection>
            </section>

            <section className="plan2">
              <FadeInSection>
                <div className="secA">
                  <div className="secACont1">
                    <h4 className="brandText">
                      Keep up with StableBlocks on Instagram
                    </h4>
                    <p className="secAp">
                      Stay up to date with the latest updates, rewards,
                      campaigns and news on StableBlocks.{" "}
                    </p>
                  </div>
                  <a
                    href="https://www.instagram.com/trendx.space/"
                    style={{ textDecoration: "none", color: "#000" }}
                    className="aTag"
                    target="_blank"
                  >
                    <button className="btn2 fBtn">
                      Follow @ourstableblocks
                    </button>
                  </a>
                </div>
              </FadeInSection>
              <FadeInSection>
                <MarqueeSlider/>
              </FadeInSection>
            </section>
            <section className="faq" ref={Faq}>
              <FadeInSection>
                <h4 className="brandText">Got Questions?</h4>
                <p className="faqText">We’ve got answers!</p>
              </FadeInSection>
              <FAQs />
            </section>
          </>
        )}
        <Footer
          abtPress={() => setShowAbt(true)}
          colabPress={() => showColab(true)}
          pCastPress={() => showPodcast(true)}
          ppPress={() => {
            setPP(true);
            window.scrollTo(0, 0);
          }}
          touPress={() => {
            shoeTermsOFuse(true);
            window.scrollTo(0, 0);
          }}
        />
      </div>
    </>
  );
}
